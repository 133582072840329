import "../Styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { RandomBlob } from "react-random-shapes";
import mediumZoom from "@wagich/medium-zoom";

// random funcs from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandom(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}
function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function renderBlobs() {
    let size = Math.min(Math.max(0.2 * window.innerWidth + 165, 200), 750);
    for (let element of document.querySelectorAll(".generated-blob")) {
        ReactDOM.render(<RandomBlob size={size} options={{ numBlobs: getRandomInt(3, 6), numControls: getRandomInt(9, 13), posWindowSize: size / 7, angleWindowSize: 0.5, handleWindowSize: 1, style: { opacity: getRandom(0.25, 0.4) } }} />, element);
    }
}

document.addEventListener("DOMContentLoaded", () => {
    renderBlobs();
    mediumZoom(".asset.is-zoomable");
});
